/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TransactionApplicant
 */
export interface TransactionApplicant {
    /**
     * Transaction Applicant
     * @type {string}
     * @memberof TransactionApplicant
     */
    id: string;
}

/**
 * Check if a given object implements the TransactionApplicant interface.
 */
export function instanceOfTransactionApplicant(value: object): value is TransactionApplicant {
    if (!('id' in value) || value['id'] === undefined) return false;
    return true;
}

export function TransactionApplicantFromJSON(json: any): TransactionApplicant {
    return TransactionApplicantFromJSONTyped(json, false);
}

export function TransactionApplicantFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransactionApplicant {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
    };
}

export function TransactionApplicantToJSON(value?: TransactionApplicant | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
    };
}

