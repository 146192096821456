/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CurrenciesCryptoCurrenciesInner } from './CurrenciesCryptoCurrenciesInner';
import {
    CurrenciesCryptoCurrenciesInnerFromJSON,
    CurrenciesCryptoCurrenciesInnerFromJSONTyped,
    CurrenciesCryptoCurrenciesInnerToJSON,
} from './CurrenciesCryptoCurrenciesInner';
import type { CurrenciesFiatCurrenciesInner } from './CurrenciesFiatCurrenciesInner';
import {
    CurrenciesFiatCurrenciesInnerFromJSON,
    CurrenciesFiatCurrenciesInnerFromJSONTyped,
    CurrenciesFiatCurrenciesInnerToJSON,
} from './CurrenciesFiatCurrenciesInner';

/**
 * 
 * @export
 * @interface Currencies
 */
export interface Currencies {
    /**
     * 
     * @type {Array<CurrenciesCryptoCurrenciesInner>}
     * @memberof Currencies
     */
    cryptoCurrencies: Array<CurrenciesCryptoCurrenciesInner>;
    /**
     * 
     * @type {Array<CurrenciesFiatCurrenciesInner>}
     * @memberof Currencies
     */
    fiatCurrencies: Array<CurrenciesFiatCurrenciesInner>;
}

/**
 * Check if a given object implements the Currencies interface.
 */
export function instanceOfCurrencies(value: object): value is Currencies {
    if (!('cryptoCurrencies' in value) || value['cryptoCurrencies'] === undefined) return false;
    if (!('fiatCurrencies' in value) || value['fiatCurrencies'] === undefined) return false;
    return true;
}

export function CurrenciesFromJSON(json: any): Currencies {
    return CurrenciesFromJSONTyped(json, false);
}

export function CurrenciesFromJSONTyped(json: any, ignoreDiscriminator: boolean): Currencies {
    if (json == null) {
        return json;
    }
    return {
        
        'cryptoCurrencies': ((json['cryptoCurrencies'] as Array<any>).map(CurrenciesCryptoCurrenciesInnerFromJSON)),
        'fiatCurrencies': ((json['fiatCurrencies'] as Array<any>).map(CurrenciesFiatCurrenciesInnerFromJSON)),
    };
}

export function CurrenciesToJSON(value?: Currencies | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'cryptoCurrencies': ((value['cryptoCurrencies'] as Array<any>).map(CurrenciesCryptoCurrenciesInnerToJSON)),
        'fiatCurrencies': ((value['fiatCurrencies'] as Array<any>).map(CurrenciesFiatCurrenciesInnerToJSON)),
    };
}

