/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TransactionOrder
 */
export interface TransactionOrder {
    /**
     * 
     * @type {string}
     * @memberof TransactionOrder
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionOrder
     */
    paymentMethod?: string;
    /**
     * Crypto currency with optional network identifier attached.
     * List of crypto currencies Simplex might send you:
     * - USDT
     * - TRX
     * - BTC
     * - USDP
     * - AAVE
     * - USDC
     * - UNI
     * - COTI-ERC20
     * - TUSD
     * - COTI
     * - BUSD
     * - HTR
     * - USDT-TRC20
     * @type {string}
     * @memberof TransactionOrder
     */
    cryptoCurrency: string;
    /**
     * Three-letter ISO currency code https://www.iso.org/iso-4217-currency-codes.html
     * @type {string}
     * @memberof TransactionOrder
     */
    fiatCurrency: string;
    /**
     * payment amount. In major units. I.e. 3.55
     * @type {string}
     * @memberof TransactionOrder
     */
    totalAmount: string;
    /**
     * crypto amount. In major units. I.e. 3.4565432344
     * @type {string}
     * @memberof TransactionOrder
     */
    cryptoAmount: string;
}

/**
 * Check if a given object implements the TransactionOrder interface.
 */
export function instanceOfTransactionOrder(value: object): value is TransactionOrder {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('cryptoCurrency' in value) || value['cryptoCurrency'] === undefined) return false;
    if (!('fiatCurrency' in value) || value['fiatCurrency'] === undefined) return false;
    if (!('totalAmount' in value) || value['totalAmount'] === undefined) return false;
    if (!('cryptoAmount' in value) || value['cryptoAmount'] === undefined) return false;
    return true;
}

export function TransactionOrderFromJSON(json: any): TransactionOrder {
    return TransactionOrderFromJSONTyped(json, false);
}

export function TransactionOrderFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransactionOrder {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'paymentMethod': json['paymentMethod'] == null ? undefined : json['paymentMethod'],
        'cryptoCurrency': json['cryptoCurrency'],
        'fiatCurrency': json['fiatCurrency'],
        'totalAmount': json['totalAmount'],
        'cryptoAmount': json['cryptoAmount'],
    };
}

export function TransactionOrderToJSON(value?: TransactionOrder | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'paymentMethod': value['paymentMethod'],
        'cryptoCurrency': value['cryptoCurrency'],
        'fiatCurrency': value['fiatCurrency'],
        'totalAmount': value['totalAmount'],
        'cryptoAmount': value['cryptoAmount'],
    };
}

