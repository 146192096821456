/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TransferInstruction
 */
export interface TransferInstruction {
    /**
     * crypto amount. In major units. I.e. 3.4565432344
     * @type {string}
     * @memberof TransferInstruction
     */
    cryptoAmount: string;
    /**
     * Crypto currency with optional network identifier attached.
     * List of crypto currencies Simplex might send you:
     * - USDT
     * - TRX
     * - BTC
     * - USDP
     * - AAVE
     * - USDC
     * - UNI
     * - COTI-ERC20
     * - TUSD
     * - COTI
     * - BUSD
     * - HTR
     * - USDT-TRC20
     * @type {string}
     * @memberof TransferInstruction
     */
    cryptoCurrency: string;
    /**
     * 
     * @type {string}
     * @memberof TransferInstruction
     */
    liquidityProvider: string;
}

/**
 * Check if a given object implements the TransferInstruction interface.
 */
export function instanceOfTransferInstruction(value: object): value is TransferInstruction {
    if (!('cryptoAmount' in value) || value['cryptoAmount'] === undefined) return false;
    if (!('cryptoCurrency' in value) || value['cryptoCurrency'] === undefined) return false;
    if (!('liquidityProvider' in value) || value['liquidityProvider'] === undefined) return false;
    return true;
}

export function TransferInstructionFromJSON(json: any): TransferInstruction {
    return TransferInstructionFromJSONTyped(json, false);
}

export function TransferInstructionFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransferInstruction {
    if (json == null) {
        return json;
    }
    return {
        
        'cryptoAmount': json['cryptoAmount'],
        'cryptoCurrency': json['cryptoCurrency'],
        'liquidityProvider': json['liquidityProvider'],
    };
}

export function TransferInstructionToJSON(value?: TransferInstruction | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'cryptoAmount': value['cryptoAmount'],
        'cryptoCurrency': value['cryptoCurrency'],
        'liquidityProvider': value['liquidityProvider'],
    };
}

