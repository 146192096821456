/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Request to create card payment instrument
 * @export
 * @interface CreateCardPaymentInstrumentCollectionRequest
 */
export interface CreateCardPaymentInstrumentCollectionRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateCardPaymentInstrumentCollectionRequest
     */
    transactionId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCardPaymentInstrumentCollectionRequest
     */
    firstMiddleName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCardPaymentInstrumentCollectionRequest
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCardPaymentInstrumentCollectionRequest
     */
    pan: string;
    /**
     * card expiry date
     * @type {string}
     * @memberof CreateCardPaymentInstrumentCollectionRequest
     */
    expiry: string;
    /**
     * user auth token for recollection
     * @type {string}
     * @memberof CreateCardPaymentInstrumentCollectionRequest
     */
    verificationToken?: string;
}

/**
 * Check if a given object implements the CreateCardPaymentInstrumentCollectionRequest interface.
 */
export function instanceOfCreateCardPaymentInstrumentCollectionRequest(value: object): value is CreateCardPaymentInstrumentCollectionRequest {
    if (!('transactionId' in value) || value['transactionId'] === undefined) return false;
    if (!('firstMiddleName' in value) || value['firstMiddleName'] === undefined) return false;
    if (!('lastName' in value) || value['lastName'] === undefined) return false;
    if (!('pan' in value) || value['pan'] === undefined) return false;
    if (!('expiry' in value) || value['expiry'] === undefined) return false;
    return true;
}

export function CreateCardPaymentInstrumentCollectionRequestFromJSON(json: any): CreateCardPaymentInstrumentCollectionRequest {
    return CreateCardPaymentInstrumentCollectionRequestFromJSONTyped(json, false);
}

export function CreateCardPaymentInstrumentCollectionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateCardPaymentInstrumentCollectionRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'transactionId': json['transactionId'],
        'firstMiddleName': json['firstMiddleName'],
        'lastName': json['lastName'],
        'pan': json['pan'],
        'expiry': json['expiry'],
        'verificationToken': json['verificationToken'] == null ? undefined : json['verificationToken'],
    };
}

export function CreateCardPaymentInstrumentCollectionRequestToJSON(value?: CreateCardPaymentInstrumentCollectionRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'transactionId': value['transactionId'],
        'firstMiddleName': value['firstMiddleName'],
        'lastName': value['lastName'],
        'pan': value['pan'],
        'expiry': value['expiry'],
        'verificationToken': value['verificationToken'],
    };
}

