/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaymentInstrumentRecollectionOtpVerificationResponse
 */
export interface PaymentInstrumentRecollectionOtpVerificationResponse {
    /**
     * 
     * @type {Date}
     * @memberof PaymentInstrumentRecollectionOtpVerificationResponse
     */
    expiresAt: Date;
    /**
     * duration in seconds for the token to be valid
     * @type {number}
     * @memberof PaymentInstrumentRecollectionOtpVerificationResponse
     */
    durationSeconds: number;
}

/**
 * Check if a given object implements the PaymentInstrumentRecollectionOtpVerificationResponse interface.
 */
export function instanceOfPaymentInstrumentRecollectionOtpVerificationResponse(value: object): value is PaymentInstrumentRecollectionOtpVerificationResponse {
    if (!('expiresAt' in value) || value['expiresAt'] === undefined) return false;
    if (!('durationSeconds' in value) || value['durationSeconds'] === undefined) return false;
    return true;
}

export function PaymentInstrumentRecollectionOtpVerificationResponseFromJSON(json: any): PaymentInstrumentRecollectionOtpVerificationResponse {
    return PaymentInstrumentRecollectionOtpVerificationResponseFromJSONTyped(json, false);
}

export function PaymentInstrumentRecollectionOtpVerificationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentInstrumentRecollectionOtpVerificationResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'expiresAt': (new Date(json['expiresAt'])),
        'durationSeconds': json['durationSeconds'],
    };
}

export function PaymentInstrumentRecollectionOtpVerificationResponseToJSON(value?: PaymentInstrumentRecollectionOtpVerificationResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'expiresAt': ((value['expiresAt']).toISOString()),
        'durationSeconds': value['durationSeconds'],
    };
}

