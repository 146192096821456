/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CryptoTransferDestinationAddress
 */
export interface CryptoTransferDestinationAddress {
    /**
     * A string representing a crypto wallet address.
     * @type {string}
     * @memberof CryptoTransferDestinationAddress
     */
    cryptoAddress: string;
    /**
     * 
     * @type {string}
     * @memberof CryptoTransferDestinationAddress
     */
    cryptoNetworkId: string;
    /**
     * 
     * @type {string}
     * @memberof CryptoTransferDestinationAddress
     */
    cryptoNetworkName: string;
}

/**
 * Check if a given object implements the CryptoTransferDestinationAddress interface.
 */
export function instanceOfCryptoTransferDestinationAddress(value: object): value is CryptoTransferDestinationAddress {
    if (!('cryptoAddress' in value) || value['cryptoAddress'] === undefined) return false;
    if (!('cryptoNetworkId' in value) || value['cryptoNetworkId'] === undefined) return false;
    if (!('cryptoNetworkName' in value) || value['cryptoNetworkName'] === undefined) return false;
    return true;
}

export function CryptoTransferDestinationAddressFromJSON(json: any): CryptoTransferDestinationAddress {
    return CryptoTransferDestinationAddressFromJSONTyped(json, false);
}

export function CryptoTransferDestinationAddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): CryptoTransferDestinationAddress {
    if (json == null) {
        return json;
    }
    return {
        
        'cryptoAddress': json['cryptoAddress'],
        'cryptoNetworkId': json['cryptoNetworkId'],
        'cryptoNetworkName': json['cryptoNetworkName'],
    };
}

export function CryptoTransferDestinationAddressToJSON(value?: CryptoTransferDestinationAddress | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'cryptoAddress': value['cryptoAddress'],
        'cryptoNetworkId': value['cryptoNetworkId'],
        'cryptoNetworkName': value['cryptoNetworkName'],
    };
}

